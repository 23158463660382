.start-box {
	/* background-image: url("../../../assets/Img/hero.jpg"); */
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.start-box::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.93);
}

.start-box .all {
	padding-top: 40px;
	padding-bottom: 150px;
}

.start-box .container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 100px;
	position: relative;
	z-index: 30;
}

.start-box .start-hero h2 {
	text-align: center;
	width: 100%;
	color: var(--font-color-04);
	margin: 0;
	font-size: var(--font-size-54);
	font-weight: var(--font-weight-04);
}

.start-box .start-content {
	width: 100%;
	max-width: 1088px;
	/* border: 1px solid red; */
	display: flex;
	align-items: flex-start;
	justify-content: start;
	flex-direction: column;
	gap: 82px;
}

.start-box .start-content .top {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: start;
	gap: 16px;
}

.start-box .start-content .top .box h4 {
	font-size: var(--font-size-28);
	text-align: center;
	color: var(--font-color-04);
	margin: 0;
	font-weight: var(--font-weight-04);
	position: relative;
	z-index: 7;
}

.start-box .start-content .top .box h4::after {
	content: "";
	position: absolute;
	height: 25px;
	width: calc(100% + 40px);
	border-radius: var(--main-border-radius-17);
	background-color: var(--default-color-01);
	z-index: 5;
	left: 50%;
	transform: translateX(-50%);
	bottom: -5px;
}

.start-box .start-content .top .box h4 span {
	z-index: 7;
	position: relative;
}

.start-box .start-content .top .box p {
	position: absolute;
	left: 50%;
	width: calc(100% - 40px);
	transform: translateX(-50%);
	text-align: center;
	bottom: 20px;
	color: var(--font-color-04);
	font-weight: var(--font-weight-03);
	font-size: var(--font-size-22);
}

.start-box .start-content .start-body {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.start-box .start-content .start-body .info {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	margin-bottom: 12px;
	width: 100%;
	height: 40px;
}

.start-box .start-content .start-body .info h1 {
	margin: 0;
	text-align: center;
	font-size: var(--font-size-24);
	font-weight: var(--font-weight-04);
	color: var(--default-color-01);
	background-color: var(--main-color-01);
	padding: 3px 16px;
	padding-top: 10px;
	line-height: 24px;
	z-index: 7;
	border-radius: var(--main-border-radius-17);
	-webkit-border-radius: var(--main-border-radius-17);
	-moz-border-radius: var(--main-border-radius-17);
	-ms-border-radius: var(--main-border-radius-17);
	-o-border-radius: var(--main-border-radius-17);
}

.start-box .start-content .start-body p {
	font-size: var(--font-size-22);
	color: var(--black-color-01);
	margin: 0;
	font-weight: var(--font-weight-03);
	margin-bottom: 60px;
	text-wrap: balance;
	text-align: center;
}

.start-box .start-content .start-body .box-video {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 48px;
	overflow: hidden;
	cursor: pointer;
}

.start-box .start-content .start-body .box-video video,
.start-box .start-content .start-body .box-video iframe,
.start-box .start-content .start-body .box-video .box-img {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: var(--main-border-radius-16);
	max-height: 547px;
}
.start-box .start-content .start-body .box-video .box-img img {
	border-radius: var(--main-border-radius-17);
}

.start-box .start-content .start-body .bt-main {
	margin: 0 auto;
	background-color: var(--default-color-01);
	width: fit-content;
	padding: 12px 94px;
}

@media (max-width: 992px) {
	.start-box .start-content .start-body .box-video video,
	.start-box .start-content .start-body .box-video iframe {
		height: 360px;
	}

	.start-box .start-content .start-body .bt-main {
		width: fit-content;
		padding: 12px 50px;
	}

	.start-box .start-hero {
		height: 200px;
	}
}

@media (max-width: 768px) {
	.start-box .start-content .top .box {
		height: 280px;
		padding: 10px;
		border-radius: 8px;
		-webkit-border-radius: 8px;
		-moz-border-radius: 8px;
		-ms-border-radius: 8px;
		-o-border-radius: 8px;
	}
	.start-box .start-content .start-body .box-video video,
	.start-box .start-content .start-body .box-video iframe {
		height: 260px;
	}
	.start-box .all {
		padding-bottom: 40px;
	}
	.start-box .start-content .start-body p {
		text-align: center;
	}
	.start-box .start-hero {
		border-radius: 14px;
	}
	.start-box .start-content .top .box h4 span {
		font-size: var(--font-size-18);
	}
}

@media (max-width: 400px) {
	.start-box .start-content .start-body .bt-main {
		width: fit-content;
		padding: 12px 25px;
		font-size: var(--font-size-20);
	}

	.start-box .start-content .top .box {
		height: 220px;
	}
}
