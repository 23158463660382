.blog-box {
	/* min-height: 1500px; */
	overflow: hidden;
}

.blog-box .container {
	padding-top: var(--main-padding-top);
}

.blog-box .container .header-blog {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow-x: auto;
	padding-bottom: 20px;
}

.blog-box .container .header-blog ul {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 !important;
	margin: 0 !important;
	gap: 10px;
	width: 900px;
	min-width: 800px;
}

.blog-box .container .header-blog ul li {
	transition: var(--transition);
	color: var(--font-color-05);
	font-size: var(--font-size-22);
	padding: 13px 27px;
}

.blog-box .container .header-blog ul li.active,
.blog-box .container .header-blog ul li:hover {
	background-color: var(--default-color-01);
	color: var(--font-color-06);
}

@media (max-width: 768px) {
	.blog-box .container .header-blog {
		align-items: flex-start;
	}
	.blog-box .container .header-blog ul {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 !important;
		margin: 0 !important;
		gap: 0px;
		width: max-content;
		min-width: max-content;
	}
	.blog-box .container .header-blog ul li {
		font-size: var(--font-size-16);
		padding: 5px 10px;
	}
}

/* =========== start-content-blog=========== */

.content-blog {
	padding: 0 0 60px 0;
}

.content-blog .box-top {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	gap: 30px;
	cursor: pointer;
}

.content-blog .box-top .box-img {
	width: 100%;
	height: 100%;
	border-radius: 18px;
	overflow: hidden;
	-webkit-border-radius: 18px;
	-moz-border-radius: 18px;
	-ms-border-radius: 18px;
	-o-border-radius: 18px;
}

.content-blog .box-top .box-img img {
	width: 100%;
	height: 100%;
	object-fit: fill;
}

.content-blog .box-top .box-left {
	width: 100%;
	flex: 1;
	display: flex;
	align-items: flex-start;
	justify-content: start;
	flex-direction: column;
	height: 100%;
	padding: 20px;
	gap: 10px;
}
@media (min-width: 992px) {
	.content-blog .box-top .box-img {
		flex: 1;
		max-width: 536px;
		max-height: 380px;
		height: 380px;
	}
	.content-blog .box-top .box-left {
		min-height: 420px;
	}
}
.content-blog .box-top .box-left h5 {
	color: var(--main-color-01);
	font-size: var(--font-size-20);
	font-weight: 500;
	margin: 0;
}

.content-blog .box-top .box-left h2 {
	color: var(--black-color-01);
	font-size: var(--font-size-48);
	font-weight: 600;
	margin: 0;
}

.content-blog .box-top .box-left p {
	color: var(--font-color-05);
	font-size: var(--font-size-20);
	font-weight: 400;
	margin: 0;
	letter-spacing: 0.4px;
}

/* =========== start-box-body=========== */

.blog-box .box-body {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
	gap: 40px;
	margin-top: 60px;
}

.blog-box .box-body .box {
	max-width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.blog-box .box-body .order {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: start;
	min-height: 380px;
	width: 100%;
	height: 100%;
	gap: 20px;
}

.blog-box .box-body .order .box-img {
	max-width: 100%;
	overflow: hidden;
	border-radius: 18px;
	width: 100%;
	-webkit-border-radius: 18px;
	-moz-border-radius: 18px;
	-ms-border-radius: 18px;
	-o-border-radius: 18px;
}

.blog-box .box-body .order .box-img img {
	width: 100%;
	height: 100%;
	object-fit: fill;
}

.blog-box .box-body .order .box-order {
	display: flex;
	align-items: flex-start;
	justify-content: start;
	flex-direction: column;
	width: 100%;
	gap: 16px;
}

.blog-box .box-body .order h6 {
	max-width: 100%;
	color: var(--main-color-01);
	font-size: var(--font-size-20);
	font-weight: 500;
	margin: 0;
	width: 100%;
}

.blog-box .box-body .order h4 {
	padding: 3px 0 0 0;
	max-width: 100%;

	color: var(--default-color-01);
	font-size: var(--font-size-28);
	font-weight: 600;
	margin-bottom: -10px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.blog-box .box-body .order p {
	max-width: 100%;
	color: var(--font-color-05);
	font-size: var(--font-size-18);
	font-weight: 400;
	margin: 0;
	letter-spacing: 0.4px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	white-space: pre-wrap;
}

.blog-box button {
	font-size: 18px;
	color: var(--main-color-01);
	background-color: var(--default-color-01);
	margin: 60px auto;
	padding: 6px 50px;
	transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
}
.blog-box button:hover {
	color: var(--default-color-01);
	background-color: var(--main-color-01);
}

@media (max-width: 768px) {
	.blog-box .box-body .order {
		justify-content: center;
	}
	.blog-box img {
		border-radius: 8px !important ;
		aspect-ratio: 2/1;
	}
	.content-blog .box-top .box-left {
		padding: 8px !important;
	}
	.blog-box .box-body {
		grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
	}
	.blog-box .box-body .order .box-order {
		padding: 8px;
	}
}
@media (max-width: 390px) {
	.blog-box .box-body {
		grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
	}
}

/* =========== end-box-body=========== */
/* =========== end-content-blog=========== */
