.soq-product-page .soq-product-body,
.skeletonLoading {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
	gap: 30px;
	margin-top: 60px;
	margin-bottom: 60px;
}

.soq-product-swiper-box {
	height: 400px;
	width: 330px;
	overflow: hidden;
	border: 1px solid var(--back-color-03);
	box-shadow: var(--box-shadow-02);
	background-color: white;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	transition: all 0.2s ease-in-out;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
}

.soq-product-swiper-box:hover {
	border: 1px solid #1bbabdee;
	box-shadow: var(--box-shadow-01);
	border-radius: 12px;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	-ms-border-radius: 12px;
	-o-border-radius: 12px;
}

.soq-product-swiper .soq-img-slide {
	width: 100% !important;
	height: 13rem;
	background-color: #fff;
	overflow: hidden;
}

.soq-product-swiper .soq-img-slide img {
	height: 13rem;
	width: auto;
	transition: transform 0.2s ease-in-out;
	-webkit-transition: transform 0.2s ease-in-out;
	-moz-transition: transform 0.2s ease-in-out;
	-ms-transition: transform 0.2s ease-in-out;
	-o-transition: transform 0.2s ease-in-out;
}

.soq-product-swiper .soq-img-slide img:hover {
	transform: scale(1.1);
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-ms-transform: scale(1.1);
	-o-transform: scale(1.1);
}

.soq-product-content {
	margin-top: 20px;
	padding: 20px 14px;
}

.soq-product-content h6 {
	color: var(--font-color-05);
	font-weight: 400;
	font-size: var(--font-size-18);
	margin-bottom: 0;
}

.head-content {
	padding-bottom: 8px;
	position: relative;
}

.head-content::after {
	position: absolute;
	content: "";
	bottom: 0;
	right: 0;
	background-color: #1bbabd95;
	width: 99%;
	height: 1px;
}

.soq-product-content .product-description {
	padding-top: 4px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	margin: 8px 0;
	color: var(--black-color-01);
	font-weight: 300;
	font-size: var(--font-size-18);
}

.soq-product-content .products-price {
	font-size: 18px;
	font-weight: 500;
	color: var(--font-color-02);
	margin-bottom: 0;
}

.products-price.in-home-page {
	background-color: #1948a0;
	width: max-content;
	margin-right: auto;
	padding: 1px 8px;
	font-weight: 400;
	gap: 1px;
	color: #fff;
	border-radius: 12px;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	-ms-border-radius: 12px;
	-o-border-radius: 12px;
}

.soq-product-content .products-price .currency {
	font-size: 16px;
}

.display-more-btn {
	color: white;
	background-color: #1948a0;
	width: 200px;
	height: 48px;

	border-radius: 30px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-ms-border-radius: 30px;
	-o-border-radius: 30px;
}

.display-more-btn:hover {
	background-color: #1948a0d6;
	color: white;
}
