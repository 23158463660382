.header {
	/* background-color: ; */
	min-height: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header .container {
	height: 100%;
	max-height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.header .container .navbar {
	width: 100%;
}

.header .container .navbar .navbar-toggler {
	box-shadow: none !important;
	border: 2px solid var(--font-color-01);
	font-size: var(--font-size-20);
}

.header .container .navbar .navbar-toggler svg {
	font-size: 28px;
	color: var(--main-color-01);
}
.header .navbar-collapse .close-nav-bar-icon .navbar-toggler {
	border: none;
	box-shadow: none;
}

.header .container .navbar .navbar-toggler.collapsed {
	border-color: var(--main-color-01);
}

.header .container .navbar .navbar-toggler.collapsed svg {
	color: var(--main-color-01);
}

.header .container .navbar .navbar-brand {
	padding: 0;
	padding-left: 20px;
	width: 114px;
	height: 74px;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.header .container .navbar .navbar-brand svg,
.header .container .navbar .navbar-brand img {
	width: 100%;
	/* max-height: 100%;
    object-fit: contain; */
}

.header .container .navbar form {
	background-color: #dce6f9;
	border-radius: var(--main-border-radius-01);
	overflow: hidden;
	display: none;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 350px;
	height: 45px;
	margin-left: 15px;
	-webkit-border-radius: var(--main-border-radius-01);
	-moz-border-radius: var(--main-border-radius-01);
	-ms-border-radius: var(--main-border-radius-01);
	-o-border-radius: var(--main-border-radius-01);
}
.header .container .navbar form.show {
	display: flex;
}

.header .container .navbar form input {
	margin: 0 !important;
	background-color: transparent;
	outline: none !important;
	border: none !important;
	box-shadow: none !important;
	color: #1948a0;
	height: 100%;
	flex: 1;
	padding-right: 0 !important;
}

.header .container .navbar form input::placeholder {
	color: #1948a0;
}

.header .container .navbar form button {
	all: unset;
	cursor: pointer;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
}

.header .container .navbar form button svg {
	color: #f75e22;
	font-size: var(--font-size-20);
}

.header .navbar-collapse .navbar-nav {
	display: flex;
	align-items: center;
	justify-content: start;
}

.header .navbar-collapse .navbar-nav a {
	color: var(--default-color-01);
	font-size: var(--font-size-18);
	font-weight: var(--font-weight-03);
	transition: var(--transition);
	border-radius: var(--main-border-radius-02);
	padding: 8px 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: max-content;
}

/* .header .navbar-collapse .navbar-nav, */
.header .navbar-collapse .navbar-nav a.active {
	background-color: #fde3d8;
	color: #f75e22;
}

/* ==================media===================== */

@media (max-width: 1400px) and (min-width: 991px) {
	.header .container .navbar .navbar-brand {
		padding-left: 0px;
		width: 120px;
	}
	.header .container .navbar form {
		height: 40px;
		margin-left: 0px;
	}

	.header .container .navbar form button {
		height: 35px;
		width: 35px;
	}
	.header .container .navbar form button svg {
		font-size: var(--font-size-16);
	}
	.header .container .navbar form input {
		font-size: 14px;
		font-weight: 300;
	}
}

@media (max-width: 992px) {
	.header .navbar-collapse {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		gap: 25px;
		margin: 0;
		height: 0;
		transition: all var(--transition);
	}
	.header .container .navbar {
		padding-bottom: 30px;
		gap: 20px;
	}
	.header .container .navbar .navbar-toggler {
		display: none;
	}

	.header .container .navbar form.show {
		position: absolute;
		top: 25px;
	}

	.header .container .navbar .navbar-nav {
		width: 100%;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.header .navbar-collapse .navbar-nav a {
		flex: 1;
		width: 100%;
		min-width: max-content;
	}
}

@media (max-width: 768px) {
	.header .navbar-collapse {
		display: flex;
		align-items: flex-start;
		justify-content: start;
		flex-direction: column-reverse;

		gap: 20px;
		margin: 0;
		height: 0;
		transition: all var(--transition);
	}
	.header .container .navbar {
		padding-bottom: 0px;
		gap: 0;
	}
	.header .container .navbar .navbar-toggler {
		display: block;
	}

	.navbar-collapse.show {
		height: 410px;
		margin: 20px 0;
	}
	.header .container .navbar form {
		margin-left: auto;
		max-width: 100%;
	}

	.header .container .navbar .navbar-nav {
		flex-direction: column;
	}

	.header .navbar-collapse .navbar-nav a {
		flex: 1;
		width: 100%;
		min-width: 95px;
	}
}

/* :: => New Style */
.close-nav-bar-icon {
	display: none;
}

@media (max-width: 768px) {
	.header .container .navbar .navbar-nav {
		justify-content: flex-start !important;
	}
	.header .navbar-collapse {
		width: 100%;

		position: fixed;

		top: 0;
		left: -400px;
		padding: 100px 40px 60px 40px;
		z-index: 555;
		min-height: 100vh;
		margin: 0;
		background-color: #f9f9f9;
		box-shadow: 0 5px 40px rgb(34 34 34 / 80%);
		opacity: 0;
		visibility: hidden;
	}
	.header .navbar-collapse.show {
		opacity: 1;
		visibility: visible;
		left: 0;
	}

	.header .navbar-collapse .close-nav-bar.show {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgb(255 255 255 / 75%);
		z-index: -1;
	}

	.header .navbar-collapse .close-nav-bar-icon {
		position: fixed;
		top: 30px;
		z-index: 555555555;
		display: block;
		font-size: 18px;
		font-weight: bold;
		cursor: pointer;
		left: auto;
		right: 30px;
	}
	.header .container .navbar form {
		position: relative !important;
		top: auto !important;
		left: auto !important;
		right: auto !important;
		bottom: auto !important;
		display: block !important;
		margin: 0 !important;
		border-radius: 0;
		order: 2;
		height: max-content !important;
		overflow: auto;
		min-height: auto !important;
		background: transparent;
	}

	.header .container .navbar form button {
		position: absolute;
		height: auto;
		width: auto;
		top: 7px;
		right: 10px;
	}
	.header .container .navbar form input {
		height: auto;
		margin-bottom: 20px;
		max-width: 100%;
		padding: 8px 40px 8px 0 !important;
		background-color: var(--back-color-02);
	}
}
