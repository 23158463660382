.swiper-product {
	height: 100%;
}

.swiper-product .box {
	display: flex;
	flex-direction: column;
	background-color: #fff;
	height: 350px;
	transition: all 0.3s ease-in-out;
	border-radius: var(--main-border-radius-02);
	overflow: hidden;
	box-shadow: 0px 3px 6px #02466a0f;
	border: 1px solid #02466a0f;
	-webkit-border-radius: var(--main-border-radius-02);
	-moz-border-radius: var(--main-border-radius-02);
	-ms-border-radius: var(--main-border-radius-02);
	-o-border-radius: var(--main-border-radius-02);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
}

.swiper-product .box:hover {
	box-shadow: 0px 3px 6px #02466a29;
	border: 1px solid #f89c77;
}
.swiper-product .box .box-img {
	flex: 1;
	margin-bottom: 8px;
	max-height: 63%;
	background-color: #fff;
}

.swiper-product .box .box-img img {
	height: 100%;
	width: 100%;
	object-fit: scale-down;
	border-radius: 6px 6px 0 0;
	-webkit-border-radius: 6px 6px 0 0;
	-moz-border-radius: 6px 6px 0 0;
	-ms-border-radius: 6px 6px 0 0;
	-o-border-radius: 6px 6px 0 0;
}

.swiper-product .box hr {
	all: unset;
	display: block;
	background-color: #fef1ec;
	height: 1px !important;
	width: 100%;
}

.swiper-product .box .content {
	height: 22%;
	width: 100%;
	padding: 6px 10px;
}

.swiper-product .box .content h6 {
	height: 100%;
	width: 100%;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	margin: 6px 6px 8px 6px;
	color: var(--default-color-01);
	font-weight: 300;
	font-size: var(--font-size-18);
}

.swiper-product .box .content .info {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	gap: 0.5rem;
}

.swiper-product .box .content .info span {
	background-color: #f89c77;
	width: 55px;
	height: 22px;
	padding: 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: var(--font-color-default);
	font-weight: 400;
	font-size: 14px;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

.swiper-product .box .content .info bdi {
	font-weight: 600;
	font-size: var(--font-size-18);
	color: var(--black-color-01);
	white-space: nowrap;
}

@media screen and (max-width: 1200px) {
	.swiper-product .box .content .info span {
		padding: 1px 4px;
		min-width: 60px;
		font-weight: 400;
		font-size: var(--font-size-14);
	}

	.swiper-product .box .content .info bdi {
		font-weight: 500;
		font-size: var(--font-size-14);
	}
}

@media screen and (max-width: 768px) {
	.swiper-product .box {
		height: 380px;
	}

	.swiper-product .box .box-img {
		max-height: 70%;
	}

	.swiper-product .box .content {
		height: 15%;
	}

	.swiper-product .box .content .info span {
		height: 20%;
	}
}
