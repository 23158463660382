.page-title {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 28px;
	height: 160px;
	background: rgb(25, 72, 160);
	background: -moz-linear-gradient(
		0deg,
		rgba(25, 72, 160, 1) 0%,
		rgba(25, 109, 160, 1) 100%
	);
	background: -webkit-linear-gradient(
		0deg,
		rgba(25, 72, 160, 1) 0%,
		rgba(25, 109, 160, 1) 100%
	);
	background: linear-gradient(
		0deg,
		rgba(25, 72, 160, 1) 0%,
		rgba(25, 109, 160, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1948a0",endColorstr="#196da0",GradientType=1);

	/* background-position: center;
    background-repeat: no-repeat;
    background-size: cover; */
	margin: 40px 0;
}

.page-title h4 {
	font: normal normal bold 28px/42px Tajawal;
	color: #f7fcff;
	letter-spacing: 0.56px;
	margin-bottom: 0;
}

.page-sub-title {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	margin-top: 30px;
}

.page-sub-title span {
	font: normal normal normal 18px/25px Tajawal;
	letter-spacing: 0.36px;
	color: #f7fcff;
}
.page-sub-title svg {
	color: #f7fcff;
}
.page-sub-title a {
	font: normal normal normal 18px/25px Tajawal;
	letter-spacing: 0.36px;
	color: #f7fcff;
}
.page-sub-title a:hover {
	color: #f7fcff;
}
@media (max-width: 576px) {
	.page-title h4 {
		font: normal normal bold 24px/32px Tajawal;
	}
	.page-sub-title a {
		font: normal normal normal 16px/22px Tajawal;
	}
	.page-sub-title span {
		font: normal normal normal 16px/22px Tajawal;
	}
}
